import React from 'react';

import { DatePickerFilterConfig, FiltersDateRangeType } from '../../../types';
import DateFilterItem from './DateFilterItem';
import styles from './styles.module.scss';

type DateFilterProps = {
  filterConfig: DatePickerFilterConfig[];
  minDate: Date;
  initialRange: Date[];
};
export const DateFilter: React.FC<DateFilterProps> = ({ filterConfig, minDate, initialRange }) => {
  return (
    <div className={styles.wrapper}>
      {filterConfig.map((item) => {
        return <DateFilterItem key={item.type} filterConfig={item} minDate={minDate} initialRange={initialRange} />;
      })}
    </div>
  );
};
