import { SectionCard } from 'components';
import { API_ROUTES } from 'const';
import { useAppSelector } from 'hooks';
import Heading from 'modules/Charts/Heading';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getUserCompetitiveStocks, getUserStockSymbol } from 'store/slices/authSlice';
import { getFiltersDatasource, getFiltersDateRangeFrom, getFiltersDateRangeTo } from 'store/slices/filtersSlice';
import useSWR from 'swr';
import { fetchWithConfig } from 'utils';

import SentimentsList from './List';

export interface IStockSentiment {
  stock: string;
  sentiments: {
    positive: number;
    negative: number;
    neutral: number;
  };
  total: number;
}

const CompetitiveSentiments = () => {
  const { t } = useTranslation();
  const filterFrom = useAppSelector(getFiltersDateRangeFrom);
  const filterTo = useAppSelector(getFiltersDateRangeTo);
  const dataSource = useAppSelector(getFiltersDatasource);
  const stockSymbol = useAppSelector(getUserStockSymbol);
  const stocks = useAppSelector(getUserCompetitiveStocks);

  const { data, error, isLoading } = useSWR<IStockSentiment[]>(
    [
      API_ROUTES.COMPETITIVE_SENTIMENTS,
      { from: filterFrom, to: filterTo, dataSource, stocks: [...stocks, stockSymbol] }
    ],
    (options: [string, Record<string, any>]) => {
      return fetchWithConfig<IStockSentiment[]>({
        url: options[0],
        params: options[1]
      });
    }
  );

  return (
    <SectionCard noBorder>
      <>
        <Heading title={t('pages.competitiveAnalysis.sentimentsTitle')} />
        <SentimentsList data={data} loading={isLoading} />
      </>
    </SectionCard>
  );
};

export default CompetitiveSentiments;
