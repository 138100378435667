import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getUserStockSymbol } from 'store/slices/authSlice';

import { SectionCard, SectionCardHeading } from '../../../components';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import useConversationCounts from '../../../hooks/useConversationCounts';
import { getTopPostTabsState, setTabFilters, updateRequestedListPage } from '../../../store/slices/filtersSlice';
import { Sentiment } from '../../../types';
import { TabConfig } from '../../../types';
import { convertNumbersToK } from '../../../utils';
import { TabFilters } from '../../index';
import PostsList from './PostsList';
import styles from './styles.module.scss';

const TopPosts = () => {
  const dispatch = useAppDispatch();
  const { posts } = useConversationCounts();
  const { t } = useTranslation();
  const { value } = useAppSelector(getTopPostTabsState);
  const stockSymbol = useAppSelector(getUserStockSymbol);

  useEffect(() => {
    if (!posts?.data) {
      return;
    }

    const { positive, negative } = posts.data;
    const key = positive ? Sentiment.positive : negative ? Sentiment.negative : Sentiment.neutral;

    if (value === key) {
      return;
    }

    dispatch(updateRequestedListPage({ count: 1, storeKeyName: 'postFilters' }));
    dispatch(setTabFilters({ keyName: 'postFilters', value: key }));
  }, [dispatch, posts.data]);

  const tabConfig: TabConfig[] = useMemo(() => {
    return [
      {
        name: t('posts.positive'),
        isActive: value === Sentiment.positive,
        toggleFn: () => {
          dispatch(updateRequestedListPage({ count: 1, storeKeyName: 'postFilters' }));
          dispatch(setTabFilters({ keyName: 'postFilters', value: Sentiment.positive }));
        },
        count: convertNumbersToK(posts?.data?.positive || '0')
      },
      {
        name: t('posts.negative'),
        isActive: value === Sentiment.negative,
        toggleFn: () => {
          dispatch(updateRequestedListPage({ count: 1, storeKeyName: 'postFilters' }));
          dispatch(setTabFilters({ keyName: 'postFilters', value: Sentiment.negative }));
        },
        count: convertNumbersToK(posts?.data?.negative || 0)
      },
      {
        name: t('posts.neutral'),
        isActive: value === Sentiment.neutral,
        toggleFn: () => {
          dispatch(updateRequestedListPage({ count: 1, storeKeyName: 'postFilters' }));
          dispatch(setTabFilters({ keyName: 'postFilters', value: Sentiment.neutral }));
        },
        count: convertNumbersToK(posts?.data?.neutral || 0)
      }
    ];
  }, [dispatch, posts?.data?.negative, posts?.data?.positive, value, t]);

  return (
    <SectionCard nativeClassName={styles.flexGrow}>
      <>
        <SectionCardHeading title={t('posts.pageTitle')} />
        <TabFilters data={tabConfig} loading={posts.isLoading} />
        <PostsList requestTrigger={!posts.imitateLoading && !!stockSymbol} />
      </>
    </SectionCard>
  );
};

export default TopPosts;
