import { Archive as ArchiveIcon, Delete as DeleteIcon, Unarchive as UnarchiveIcon } from '@mui/icons-material';
import {
  NegativeCampaning,
  NegativeSentiment,
  NewHater,
  OngoinChaterDomn,
  PositiveSentiment,
  PumpAttemptDown,
  PumpAttemptUp,
  SignChatterDown,
  SignChatterUp,
  StockManipulator,
  StockMentionInfl,
  StockSubRedit,
  StockTwitsSign,
  TrendIcon
} from 'components/SvgComponents';
import { API_ROUTES } from 'const';
import { useAppDispatch, useAppSelector } from 'hooks';
import { AlertTypes, IAlertsListItemResponse } from 'modules/Alerts/types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  clearActiveAlert,
  getActiveAlertId,
  getHiddenTypes,
  getIsLoadingDetailsAlert,
  incrementUnreadAlerts,
  setActiveAlert
} from 'store/slices/alertsSlice';
import { getUserStockSymbol } from 'store/slices/authSlice';
import { AlertStorageTypes } from 'types';
import { fetchWithConfig } from 'utils';

import { getAlertsFiltersTabsState, setAlertsStorageCounters } from '../../../store/slices/filtersSlice';

const useAlertActions = (alert: IAlertsListItemResponse, loading: boolean) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const activeId = useAppSelector(getActiveAlertId);
  const isLoadingDetails = useAppSelector(getIsLoadingDetailsAlert);
  const { value: activeStorageType, counters: storageCounters } = useAppSelector(getAlertsFiltersTabsState);
  const hiddenTypes = useAppSelector(getHiddenTypes);
  const activeStock = useAppSelector(getUserStockSymbol);

  const { isRead, _id, type, title } = alert;

  const [isNew, setIsNew] = useState(!isRead);
  const [isHide, setIsHide] = useState(false);

  const getAlertIconByType = (type: AlertTypes | string, title: string) => {
    const nameSplited = title.split(' ');
    const lastNameKey = nameSplited[nameSplited.length - 1].toLowerCase();
    const isIncrease = lastNameKey === 'increase';

    const config: { [key: string]: React.FC } = {
      [AlertTypes.VOLUME_INCREASE]: SignChatterUp,
      [AlertTypes.VOLUME_DECREASE]: SignChatterDown,
      [AlertTypes.CHATTER_DECREASE]: OngoinChaterDomn,
      [AlertTypes.NEGATIVE_SENTIMENT]: isIncrease ? NegativeSentiment : PositiveSentiment,
      [AlertTypes.POSITIVE_SENTIMENT]: isIncrease ? PositiveSentiment : NegativeSentiment,
      [AlertTypes.INFLUENCER]: StockMentionInfl,
      [AlertTypes.STOCK_MANIPULATOR]: StockManipulator,
      [AlertTypes.SIGNIFICANT_SUB_REDDIT]: StockSubRedit,
      [AlertTypes.INCREASE_FOLLOWERS]: StockTwitsSign,
      [AlertTypes.PUMP_ATTEMPT]: PumpAttemptUp,
      [AlertTypes.DUMP_EFFORTS]: PumpAttemptDown,
      [AlertTypes.HATER_IDENTIFIED]: NewHater,
      [AlertTypes.NEGATIVE_CAMPAIGN]: NegativeCampaning
    };

    return config[type] || TrendIcon;
  };

  useEffect(() => {
    if (!loading && activeStorageType === hiddenTypes) {
      setIsHide(true);
    }
  }, [loading, activeStock, storageCounters, activeStorageType, hiddenTypes]);

  const alertRequest = useCallback(
    async (e: any, { url, method }: { url: string; method: 'POST' | 'DELETE' }, callback: () => void) => {
      e.stopPropagation();

      try {
        await fetchWithConfig<any>({
          url,
          method
        });

        setIsHide(true);

        if (isNew) {
          dispatch(incrementUnreadAlerts());
        }

        if (activeId === _id) {
          dispatch(clearActiveAlert());
        }

        callback();
      } catch (error) {
        console.error(error);
      }
    },
    [_id, activeId, dispatch, isNew]
  );

  const unarchiveAlert = useCallback(
    async (e: any) => {
      await alertRequest(
        e,
        {
          url: `${API_ROUTES.ALERTS_UNARCHIVE.replace(':stockSymbol', activeStock)}/${_id}`,
          method: 'POST'
        },
        () => {
          dispatch(
            setAlertsStorageCounters({
              ...storageCounters,
              [AlertStorageTypes.ARCHIVED]: storageCounters[AlertStorageTypes.ARCHIVED] - 1,
              [AlertStorageTypes.INBOX]: storageCounters[AlertStorageTypes.INBOX] + 1
            })
          );
        }
      );
    },
    [_id, activeStock, alertRequest, dispatch, storageCounters]
  );

  const archiveAlert = useCallback(
    async (e: any) => {
      await alertRequest(
        e,
        {
          url: `${API_ROUTES.ALERTS_ARCHIVE.replace(':stockSymbol', activeStock)}/${_id}`,
          method: 'POST'
        },
        () => {
          dispatch(
            setAlertsStorageCounters({
              [AlertStorageTypes.INBOX]: storageCounters[AlertStorageTypes.INBOX] - 1,
              [AlertStorageTypes.ARCHIVED]: storageCounters[AlertStorageTypes.ARCHIVED] + 1
            })
          );
        }
      );
    },
    [_id, activeStock, alertRequest, dispatch, storageCounters]
  );

  const deleteAlert = useCallback(
    async (e: any) => {
      await alertRequest(
        e,
        {
          url: `${API_ROUTES.ALERTS}/${activeStock}/${_id}`,
          method: 'DELETE'
        },
        () => {
          dispatch(
            setAlertsStorageCounters({
              ...storageCounters,
              [AlertStorageTypes.ARCHIVED]: storageCounters[AlertStorageTypes.ARCHIVED] - 1
            })
          );
        }
      );
    },
    [_id, activeStock, alertRequest, dispatch, storageCounters]
  );

  const setRead = async () => {
    try {
      await fetchWithConfig<any>({
        url: `${API_ROUTES.ALERTS_READ.replace(':stockSymbol', activeStock)}/${_id}`,
        method: 'POST'
      });
      setIsNew(false);
    } catch (error) {
      setIsNew(false);
    }
  };

  const onAlertClick = async () => {
    if (loading || isLoadingDetails) {
      return;
    }

    if (isNew) {
      setRead().then(() => {});
      dispatch(incrementUnreadAlerts());
    }
    dispatch(setActiveAlert(_id));
  };

  const alertActions = useMemo(() => {
    if (activeStorageType === AlertStorageTypes.INBOX) {
      return [
        {
          action: archiveAlert,
          Icon: ArchiveIcon,
          title: t('alerts.actions.archive')
        }
      ];
    }

    if (activeStorageType === AlertStorageTypes.ARCHIVED) {
      return [
        {
          action: unarchiveAlert,
          Icon: UnarchiveIcon,
          title: t('alerts.actions.unarchive')
        },
        {
          action: deleteAlert,
          Icon: DeleteIcon,
          title: t('alerts.actions.delete')
        }
      ];
    }

    return [];
  }, [activeStorageType, archiveAlert, deleteAlert, t, unarchiveAlert]);

  return {
    activeId,
    isNew,
    isHide,
    alertActions,
    onAlertClick,
    IconByType: getAlertIconByType(type, title)
  };
};

export default useAlertActions;
