import React from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import CardSkeleton from '../../../components/SentimentalsBlocks/CardSkeleton';
import { AlertIcon, Barchart, TrendIcon, UserIconTrend } from '../../../components/SvgComponents';
import { API_ROUTES } from '../../../const';
import { useAppSelector } from '../../../hooks';
import { getUserStockSymbol } from '../../../store/slices/authSlice';
import { fetchWithConfig } from '../../../utils';
import Card from '../Card';
import { ExecutiveSummaryResponse, SummaryType } from '../types';
import styles from './styles.module.scss';

const SummaryList = () => {
  const { t } = useTranslation();
  const stockSymbol = useAppSelector(getUserStockSymbol);

  const { data, error, isLoading } = useSWR<ExecutiveSummaryResponse>(
    stockSymbol ? `${API_ROUTES.EXECUTIVE_SUMMARY}/${stockSymbol}` : null,
    (url) => {
      return fetchWithConfig({
        url
      });
    }
  );

  return (
    <div className={styles.wrapper}>
      {!stockSymbol || isLoading ? (
        <>
          {Array(4)
            .fill(1)
            .map((item, index) => (
              <CardSkeleton key={index} />
            ))}
        </>
      ) : data ? (
        <>
          <Card
            loading={isLoading}
            title={t('executiveSummary.mentions')}
            Icon={Barchart}
            data={data.mentions}
            type={SummaryType.simple}
          />
          <Card
            loading={isLoading}
            title={t('executiveSummary.sentiment')}
            Icon={TrendIcon}
            data={data.sentiment}
            type={SummaryType.chart}
          />
          <Card
            loading={isLoading}
            title={t('executiveSummary.bots')}
            Icon={UserIconTrend}
            data={data.bots}
            type={SummaryType.simple}
          />
          <Card
            loading={isLoading}
            title={t('executiveSummary.alerts')}
            Icon={AlertIcon}
            data={data.alerts}
            type={SummaryType.simple}
          />
        </>
      ) : null}
    </div>
  );
};

export default SummaryList;
