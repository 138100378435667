import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { FormControl, MenuItem, Paper, Select, Tooltip } from '@mui/material';
import classNames from 'classnames';
import React, { useState } from 'react';
import { BaseSyntheticEvent, useEffect } from 'react';
import { Controller, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BaseRecord } from 'types';

import { Spinner } from '../Spinner';
import styles from './styles.module.scss';

export type CustomSelectProps = {
  placeholder?: string;
  loading?: boolean;
  name: string;
  control?: any;
  errors?: FieldError | undefined;
  register?: any;
  isMultiple?: boolean;
  defaultValue?: string | string[];
  required?: boolean;
  onFieldChange?: (value: string | string[]) => void;
  maxValues?: number;
  withSearch?: boolean;
  toolTip?: string;
};
export const CustomSelect = <TData extends BaseRecord = any>(props: CustomSelectProps & { data: TData[] }) => {
  const {
    data,
    placeholder,
    loading = false,
    name,
    control,
    isMultiple = false,
    defaultValue,
    register,
    required,
    errors,
    maxValues,
    onFieldChange = () => {},
    toolTip,
    withSearch
  } = props;

  const [searchVal, setSearchVal] = useState('');

  const { t } = useTranslation();

  const multiple = isMultiple ? { multiple: true } : {};
  useEffect(() => {
    register(name, required ? { required: t('errors.requiredField') } : {});
  }, [name, register, required]);

  const onSearch = (e: BaseSyntheticEvent) => {
    setSearchVal(e.target.value);
  };

  const stopImmediatePropagation = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <div className={styles.wrapper}>
      {!!placeholder && (
        <div className={styles.labelWrapper}>
          <span className={styles.label}>{placeholder}</span>
          {toolTip && (
            <Tooltip title={toolTip}>
              <HelpOutlineIcon fontSize="small" />
            </Tooltip>
          )}
        </div>
      )}
      <FormControl fullWidth>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue ? defaultValue : isMultiple ? [] : ''}
          render={({ field }) => {
            return (
              <>
                <Select
                  {...field}
                  {...multiple}
                  onClose={() => {
                    setTimeout(() => {
                      setSearchVal('');
                    }, 500);
                  }}
                  sx={{ width: '100%' }}
                  onChange={(event) => {
                    if (isMultiple && maxValues && maxValues === 1) {
                      const lastSelected = event.target.value[event.target.value.length - 1];
                      field.onChange([lastSelected]);
                      onFieldChange([lastSelected]);
                      return;
                    }

                    field.onChange(event);
                    onFieldChange(event.target.value);
                  }}
                >
                  {withSearch && (
                    <MenuItem
                      disableRipple
                      className={styles.searchMenuItem}
                      onKeyDown={(e) => e.stopPropagation()}
                      onClickCapture={stopImmediatePropagation}
                    >
                      <Paper className={styles.searchWrapper}>
                        <input
                          value={searchVal}
                          className={styles.searchInput}
                          onChange={onSearch}
                          type="text"
                          placeholder={t('labels.searchTimeZone')}
                        />
                      </Paper>
                    </MenuItem>
                  )}
                  {loading && !data?.length ? (
                    <Spinner />
                  ) : (
                    data?.map((item: TData, index: number) => {
                      const isHidden = withSearch && !item.name.toLowerCase().includes(searchVal.toLowerCase());

                      return (
                        <MenuItem
                          className={classNames({ [styles.hiddenMenuItem]: isHidden })}
                          key={index}
                          value={item.symbol || item._id}
                        >
                          {item.name}
                        </MenuItem>
                      );
                    })
                  )}
                </Select>
              </>
            );
          }}
        />
      </FormControl>
      {!!errors && <span className={styles.error}>{errors.message}</span>}
    </div>
  );
};
