import * as React from 'react';
import { SVGProps } from 'react';

export const StockManipulator = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12 15c-3.17 0-5.99 1.53-7.784 3.906-.386.511-.58.767-.573 1.112.005.267.172.604.382.769.272.213.649.213 1.402.213h13.146c.753 0 1.13 0 1.402-.213.21-.165.377-.502.382-.769.006-.345-.187-.6-.573-1.112C17.99 16.531 15.17 15 12 15ZM12 12a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Z"
      />
      <g clipPath="url(#b)">
        <path fill="#101828" d="M18 23a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z" />
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.333}
          d="M18 20v-2m0-2h.005M23 18a5 5 0 1 1-10 0 5 5 0 0 1 10 0Z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
      <clipPath id="b">
        <path fill="#fff" d="M12 12h12v12H12z" />
      </clipPath>
    </defs>
  </svg>
);
