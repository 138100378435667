import { SectionCard } from 'components';
import { API_ROUTES } from 'const';
import { useAppSelector } from 'hooks';
import Heading from 'modules/Charts/Heading';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getUserCompetitiveStocks, getUserStockSymbol } from 'store/slices/authSlice';
import { getFiltersDatasource, getFiltersDateRangeFrom, getFiltersDateRangeTo } from 'store/slices/filtersSlice';
import useSWR from 'swr';
import { fetchWithConfig } from 'utils';

import MentionsList from './List';

export interface IStockMention {
  stock: string;
  count: number;
}

const CompetitiveMentions = () => {
  const { t } = useTranslation();
  const filterFrom = useAppSelector(getFiltersDateRangeFrom);
  const filterTo = useAppSelector(getFiltersDateRangeTo);
  const dataSource = useAppSelector(getFiltersDatasource);
  const stockSymbol = useAppSelector(getUserStockSymbol);
  const stocks = useAppSelector(getUserCompetitiveStocks);

  const { data, error, isLoading } = useSWR<IStockMention[]>(
    [API_ROUTES.COMPETITIVE_MENTIONS, { from: filterFrom, to: filterTo, dataSource, stocks: [...stocks, stockSymbol] }],
    (options: [string, Record<string, any>]) => {
      return fetchWithConfig<IStockMention[]>({
        url: options[0],
        params: options[1]
      });
    }
  );

  return (
    <SectionCard noBorder>
      <>
        <Heading title={t('pages.competitiveAnalysis.mentionsTitle')} />
        <MentionsList data={data} loading={isLoading} />
      </>
    </SectionCard>
  );
};

export default CompetitiveMentions;
