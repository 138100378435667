import { Datasource, IAlertConversation, IAlertUser, MongoDbId } from '../../types';

export interface IAlertsDetailsResponse extends MongoDbId {
  title: string;
  subTitle: string;
  type: AlertTypes;
  createdAt: string;
  recommendation: string;
  insights: {
    header: string;
    body: string[];
  };
  dataSource: Datasource;
  isRead: boolean;
  user?: IAlertUser;
  conversation?: IAlertConversation;
}

export interface IAlertsListItemResponse {
  _id: string;
  title: string;
  type: AlertTypes;
  createdAt: string;
  dataSource: Datasource;
  isRead: boolean;
}

export enum AlertTypes {
  VOLUME_INCREASE = 1,
  VOLUME_DECREASE = 2,
  CHATTER_DECREASE = 3,
  NEGATIVE_SENTIMENT = 4,
  POSITIVE_SENTIMENT = 5,
  INFLUENCER = 6,
  STOCK_MANIPULATOR = 7,
  SIGNIFICANT_SUB_REDDIT = 8,
  INCREASE_FOLLOWERS = 9,
  PUMP_ATTEMPT = 10,
  DUMP_EFFORTS = 11,
  HATER_IDENTIFIED = 12,
  NEGATIVE_CAMPAIGN = 13
}
