import { createAction, createSlice } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { formatDate } from 'utils';

import {
  AlertStorageTypes,
  Datasource,
  FiltersDateRangeType,
  IFilters,
  ResponderTabsTypes,
  Sentiment,
  StoreFiltersKeyName,
  UserFilter
} from '../../types';
import { RootState } from '../index';

const initialState: IFilters = {
  dateRange: {
    type: FiltersDateRangeType.h24,
    from: formatDate(DateTime.now().minus({ days: 1 }).toJSDate()),
    to: formatDate(DateTime.now().toJSDate()),
    loading: true
  },
  dataSource: [
    Datasource.Twitter,
    Datasource.Reddit,
    Datasource.YahooFinance,
    Datasource.StockTwits,
    Datasource.YouTube
  ],
  withoutBotsAndRobots: false,
  competitiveActiveStocks: [],
  postFilters: {
    tabs: {
      value: Sentiment.positive,
      page: 1,
      count: 0
    }
  },
  usersFilters: {
    activeUser: null,
    tabs: {
      value: UserFilter.followers,
      page: 1,
      count: 0
    }
  },
  alertsFilters: {
    tabs: {
      value: AlertStorageTypes.INBOX,
      page: 1,
      counters: {
        [AlertStorageTypes.INBOX]: 0,
        [AlertStorageTypes.ARCHIVED]: 0
      }
    }
  },
  responderFilters: {
    tabs: {
      value: ResponderTabsTypes.ALL,
      page: 1,
      counters: {
        [ResponderTabsTypes.ALL]: 0,
        [ResponderTabsTypes.REPLIED]: 0,
        [ResponderTabsTypes.ARCHIVED]: 0
      }
    }
  },
  isRangeOpen: false
};

export const getFiltersDateRangeFrom = (store: RootState) => store.filters.dateRange.from;
export const getFiltersDateRangeTo = (store: RootState) => store.filters.dateRange.to;

export const getFiltersDatasource = (store: RootState) => store.filters.dataSource;
export const getFilterWithoutBotsAndRobots = (store: RootState) => store.filters.withoutBotsAndRobots;
export const getFiltersDataRangeLoadingState = (store: RootState) => store.filters.dateRange.loading;
export const getFilterDateRangeType = (store: RootState) => store.filters.dateRange.type;

export const getTopPostTabsState = (store: RootState) => store.filters.postFilters.tabs;
export const getTopUsersTabsState = (store: RootState) => store.filters.usersFilters.tabs;
export const getTopUsersActiveId = (store: RootState) => store.filters.usersFilters.activeUser;
export const getAlertsFiltersTabsState = (store: RootState) => store.filters.alertsFilters.tabs;
export const getResponderFiltersTabsState = (store: RootState) => store.filters.responderFilters.tabs;
export const getRangeOpenState = (store: RootState) => store.filters.isRangeOpen;
export const getCompetitiveActiveStocks = (store: RootState) => store.filters.competitiveActiveStocks;

export const setDatasourceFilters = createAction<Datasource>('filtersSlice/setDatasourceFilters');
export const resetDatasourceFilters = createAction('filtersSlice/resetDatasourceFilters');
export const setWithBotsAndRobots = createAction<boolean>('filtersSlice/setWithBotsAndRobots');
export const setCompetitiveActiveStock = createAction<string[]>('filtersSlice/setCompetitiveActiveStock');
export const setLoadingState = createAction<{ key: string; value: boolean }>('filtersSlice/setLoadingState');
export const setDateRangeFilter = createAction<{ type: FiltersDateRangeType; from: string; to: string }>(
  'filtersSlice/setDateRangeFilter'
);
export const initDateRangeFilter = createAction<string>('filtersSlice/initDateRangeFilter');
export const resetFilters = createAction<StoreFiltersKeyName[] | undefined>('filtersSlice/resetFilters');
export const resetInfinityScrollFilters = createAction('filtersSlice/resetInfinityScrollFilters');

export const setRangeFilterType = createAction<FiltersDateRangeType>('filtersSlice/setRangeType');
export const setTopActiveUserId = createAction<string | null>('filtersSlice/setTopActiveUserId');
export const setAlertsStorageCounters = createAction<
  { key: AlertStorageTypes; value: number } | typeof initialState.alertsFilters.tabs.counters
>('alertsSlice/setStorageCounters');

export const setTabFilters = createAction<{
  keyName: StoreFiltersKeyName;
  value: UserFilter | Sentiment | AlertStorageTypes | ResponderTabsTypes;
}>('setTabFilters/setTabFilters');

export const updateRequestedListPage = createAction<{ count?: number; storeKeyName: StoreFiltersKeyName }>(
  'filtersSlice/updatePostListPage'
);
export const setOpenRangeState = createAction<boolean>('toggleOpenRangeState/updatePostListPage');

export const updateCountFn = createAction<{ key: Partial<keyof typeof initialState>; value: number }>(
  'filtersSlice/updateCountFn'
);
const filtersSlice = createSlice({
  name: 'filtersSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setDatasourceFilters, (store, { payload }) => {
        const isInArray = store.dataSource.some((item) => item === payload);
        if (store.dataSource.length === 1 && store.dataSource[0] === payload) {
          store.dataSource = initialState.dataSource;
          return;
        }

        if (store.dataSource.length === initialState.dataSource.length) {
          store.dataSource = [payload];
          return;
        }

        store.dataSource = isInArray
          ? store.dataSource.filter((item) => item !== payload)
          : [...store.dataSource, payload];
      })
      .addCase(initDateRangeFilter, (store, { payload: selectedTimeZone }) => {
        store.dateRange.from = formatDate(DateTime.now().minus({ hour: 24 }).toJSDate(), selectedTimeZone, false);
        store.dateRange.to = formatDate(new Date(), selectedTimeZone, false);
      })
      .addCase(resetDatasourceFilters, (store) => {
        store.dataSource = initialState.dataSource;
      })
      .addCase(setTopActiveUserId, (store, { payload }) => {
        store.usersFilters.activeUser = payload;
      })
      .addCase(setWithBotsAndRobots, (store, { payload }) => {
        store.withoutBotsAndRobots = payload;
      })
      .addCase(setLoadingState, (store, { payload }) => {
        const { key, value } = payload;
        //@ts-ignore
        store[key].loading = value;
      })
      .addCase(setDateRangeFilter, (store, { payload }) => {
        store.dateRange.type = payload.type;
        store.dateRange.from = payload.from;
        store.dateRange.to = payload.to;
      })
      .addCase(setCompetitiveActiveStock, (store, { payload }) => {
        store.competitiveActiveStocks = payload;
      })
      .addCase(setRangeFilterType, (store, { payload }) => {
        store.dateRange.type = payload;
      })
      .addCase(updateRequestedListPage, (store, { payload }) => {
        payload?.count
          ? (store[payload.storeKeyName].tabs.page = payload.count)
          : store[payload.storeKeyName].tabs.page++;
      })
      .addCase(setOpenRangeState, (store, { payload }) => {
        store.isRangeOpen = payload;
      })
      .addCase(updateCountFn, (store, { payload }) => {
        const { key, value } = payload;
        //@ts-ignore
        store[key].count = value;
      })
      .addCase(resetInfinityScrollFilters, (store) => {
        return {
          ...store,
          usersFilters: initialState.usersFilters,
          alertsFilters: initialState.alertsFilters,
          postFilters: initialState.postFilters,
          responderFilters: initialState.responderFilters
        };
      })
      .addCase(resetFilters, (store, { payload }) => {
        const isArray = Array.isArray(payload);
        if (isArray) {
          payload?.forEach((item) => {
            //@ts-ignore
            store[item] = initialState[item];
          });
          return store;
        }

        return {
          ...initialState,
          dateRange: {
            ...initialState.dateRange,
            loading: store.dateRange.loading
          }
        };
      })
      .addCase(setTabFilters, (store, { payload }) => {
        const { keyName, value } = payload;
        //@ts-ignore
        store[keyName].tabs.value = value;
      })
      .addCase(setAlertsStorageCounters, (store, { payload }) => {
        if ('key' in payload) {
          store.alertsFilters.tabs.counters = {
            ...store.alertsFilters.tabs.counters,
            [payload.key]: payload.value
          };

          return store;
        }

        store.alertsFilters.tabs.counters = payload;
      });
  }
});

export const filtersReducers = filtersSlice.reducer;
