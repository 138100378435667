import classNames from 'classnames';
import { Layout, Spinner } from 'components';
import { useAppSelector } from 'hooks';
import StockFilterHeader from 'modules/StockFilterHeader';
import React from 'react';
import { getUiConfig } from 'store/slices/authSlice';
import { sortArrByKey } from 'utils';

import styles from './styles.module.scss';
import { generalUiConfigComponents } from './ui-config';

const HomePage: React.FC<{ blurContent?: boolean }> = ({ blurContent }) => {
  const uiConfig = useAppSelector(getUiConfig);

  if (!uiConfig) {
    return <Spinner />;
  }

  const {
    config: {
      homepage: { general }
    }
  } = uiConfig;

  const sortedGeneral = sortArrByKey(general, 'order');

  return (
    <Layout blurContent={blurContent}>
      <>
        <div className={classNames(styles.wrapper, styles.topWrapper)}>
          <StockFilterHeader withChangeUIOrder />
          {sortedGeneral.map(({ uiKey, enabled }) => {
            const Component = generalUiConfigComponents[uiKey];

            if (!Component || !enabled) {
              return null;
            }

            return <Component key={uiKey} />;
          })}
        </div>
      </>
    </Layout>
  );
};
export default HomePage;
