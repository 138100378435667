import { Avatar, Skeleton, Tooltip } from '@mui/material';
import classNames from 'classnames';
import React, { JSX } from 'react';
import { capitalizeFirstLetter } from 'utils';

import { ValueWithTooltip } from '../../../../components';
import { Datasource } from '../../../../types';
import styles from './styles.module.scss';

export type DataSourceTagItemProps = {
  Icon?: JSX.Element;
  title: string;
  counter?: number;
  counters?: Record<string, number>;
  dataSource: Datasource | string;
  loading: boolean;
  isActive: boolean;
  onClick: () => void;
  disabled?: boolean;
};

const CountersTooltip = ({
  counters,
  children
}: {
  counters?: Record<string, number>;
  children: React.ReactElement;
}) => {
  if (!counters) {
    return null;
  }

  return (
    <Tooltip
      title={
        <div className={styles.countersWrapper}>
          {Object.keys(counters).map((key) => (
            <div key={key}>
              {capitalizeFirstLetter(key)}: {counters[key]}
            </div>
          ))}
        </div>
      }
      placement="top"
    >
      {children}
    </Tooltip>
  );
};

const DataSourceTagItem: React.FC<DataSourceTagItemProps> = ({
  Icon,
  title,
  counter,
  counters,
  dataSource,
  disabled,
  loading,
  isActive,
  onClick
}) => {
  const Content = (
    <div className={styles.container}>
      <button
        onClick={onClick}
        className={classNames(styles.wrapper, { [styles.isActive]: isActive })}
        disabled={disabled}
      >
        <div className={classNames(styles.flex, { [styles.transparent]: disabled })}>
          {loading ? (
            <>
              <Skeleton width={16} height={16} variant="circular" sx={{ marginRight: 1 }}>
                <Avatar />
              </Skeleton>
              <Skeleton width={50} />
            </>
          ) : (
            <>
              {Icon && Icon}
              <span className={styles.title}>{title}</span>
              {!!counter && (
                <>
                  &nbsp;(
                  <ValueWithTooltip value={counter} />)
                </>
              )}
              {!!counters && (
                <>
                  &nbsp;(
                  {Object.keys(counters)
                    .map((key) => counters[key])
                    .join('/')}
                  )
                </>
              )}
            </>
          )}
        </div>
      </button>
    </div>
  );

  if (!counters) {
    return Content;
  }

  return <CountersTooltip counters={counters}>{Content}</CountersTooltip>;
};

export default DataSourceTagItem;
