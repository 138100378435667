import * as React from 'react';
import { SVGProps } from 'react';

export const StockSubRedit = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M17.359 2.696c-.017.113-.026.23-.026.348 0 1.197.896 2.168 2 2.168 1.105 0 2-.97 2-2.168 0-1.197-.895-2.168-2-2.168-.492 0-.942.193-1.29.513L12.847 0l-1.877 8.139c-2.497.178-4.743.998-6.437 2.235a2.557 2.557 0 0 0-1.867-.826C1.194 9.548 0 10.842 0 12.438c0 1.111.578 2.076 1.426 2.56a6.015 6.015 0 0 0-.093 1.053C1.333 20.441 6.11 24 12 24s10.667-3.559 10.667-7.949c0-.357-.032-.709-.093-1.053.848-.484 1.426-1.449 1.426-2.56 0-1.596-1.194-2.89-2.667-2.89-.727 0-1.386.315-1.867.826-1.846-1.348-4.346-2.2-7.113-2.267l1.465-6.354 3.541.943ZM7.333 16.774c1.105 0 2-.97 2-2.168 0-1.197-.895-2.168-2-2.168-1.104 0-2 .97-2 2.168 0 1.197.896 2.168 2 2.168Zm9.334 0c1.104 0 2-.97 2-2.168 0-1.197-.896-2.168-2-2.168-1.105 0-2 .97-2 2.168 0 1.197.895 2.168 2 2.168Zm-8.964 2.289c-.306-.221-.72-.132-.924.2-.205.332-.122.781.185 1.002 1.45 1.049 3.243 1.573 5.036 1.573 1.793 0 3.586-.524 5.037-1.573.306-.22.389-.67.184-1.002-.204-.332-.618-.421-.924-.2-1.227.887-2.762 1.33-4.297 1.33-.91 0-1.82-.156-2.667-.468a6.788 6.788 0 0 1-1.63-.862Z"
      clipRule="evenodd"
    />
  </svg>
);
