import ApexCharts from 'apexcharts';
import classNames from 'classnames';
import { ChatterIcon, IndexIcon, PriceIcon, VolumeIcon } from 'components/SvgComponents';
import useCompetitiveStocks from 'hooks/useCompetitiveStocks';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { SectionCard } from '../../../components';
import { API_ROUTES } from '../../../const';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getSelectedTimeZone, getUserStockSymbol } from '../../../store/slices/authSlice';
import {
  getCompetitiveActiveStocks,
  getFilterDateRangeType,
  getFiltersDateRangeFrom,
  getFiltersDateRangeTo,
  setLoadingState
} from '../../../store/slices/filtersSlice';
import { convertNumbersToK, fetchWithConfig } from '../../../utils';
import ChartSpinner from '../ChartSpinner';
import MixedCharts from '../CommonCharts/MIxedCharts';
import Heading from '../Heading';
import { ChartSeriesName, ILinearChartResponse, ILinearChartSeriesItem } from '../types';
import {
  getFormattedSeries,
  getSerieKeyFromName,
  getXaxisLabels,
  getXaxisTooltipLabel,
  preloaderLinearChartData,
  renderCompetitiveTooltip
} from '../utils';
import Legends from './Legends';
import SeriesFilter from './SeriesFilter';
import styles from './styles.module.scss';

interface ISelectedTooltip {
  id: number;
  layout: string;
  offsetX: number;
}

const CompetitiveChart = () => {
  const activeStocks = useAppSelector(getCompetitiveActiveStocks);
  const filterFrom = useAppSelector(getFiltersDateRangeFrom);
  const filterTo = useAppSelector(getFiltersDateRangeTo);
  const filtersRangeType = useAppSelector(getFilterDateRangeType);
  const stockSymbol = useAppSelector(getUserStockSymbol);
  const selectedTimeZone = useAppSelector(getSelectedTimeZone);
  const dispatch = useAppDispatch();
  const { competitiveStocks: competitiveStocksInfo, mainStock } = useCompetitiveStocks();
  const [selectedLegendStock, setSelectedLegendStock] = useState('');
  const [selectedSeries, setSelectedSeries] = useState('');
  const [selectedTooltips, setSelectedTooltips] = useState<ISelectedTooltip[]>([]);
  const { t } = useTranslation();

  const tooltipsCount = useRef(0);

  const chartId = 'CompetitiveChart';

  const { data, error, isLoading } = useSWR<any>(
    stockSymbol
      ? [
          API_ROUTES.COMPETITIVE_CHART,
          {
            from: filterFrom,
            to: filterTo,
            stocks: [...activeStocks, mainStock.symbol]
          }
        ]
      : null,
    (options: [string, Record<string, any>]) => {
      return fetchWithConfig({
        url: options[0],
        params: options[1]
      });
    }
  );

  useEffect(() => {
    setSelectedLegendStock('');
    setSelectedSeries('');
  }, [activeStocks]);

  useEffect(() => {
    dispatch(setLoadingState({ key: 'dateRange', value: isLoading }));
  }, [dispatch, isLoading]);

  const renderData: ILinearChartResponse = useMemo(() => {
    return isLoading ? preloaderLinearChartData : data || { series: [], dates: [] };
  }, [data, isLoading, competitiveStocksInfo]);

  const colors = ['#FF9761', '#1570EF', '#F04438', '#49D28D'];

  const seriesFilters = useMemo(() => {
    return [
      { name: t('charts.labels.all'), value: '' },
      { name: t('charts.labels.chatter'), value: ChartSeriesName.chatter, icon: <ChatterIcon /> },
      { name: t('charts.labels.price2'), value: ChartSeriesName.price, icon: <PriceIcon /> },
      { name: t('charts.labels.volume'), value: ChartSeriesName.volume, icon: <VolumeIcon /> },
      { name: t('charts.labels.index'), value: ChartSeriesName.index, icon: <IndexIcon /> }
    ];
  }, [t]);

  useEffect(() => {
    setSelectedTooltips([]);
    tooltipsCount.current = 0;
  }, [selectedSeries, activeStocks]);

  const { legends, newSeries } = useMemo(() => {
    const legends = renderData.series
      .reduce((acc, item) => {
        const { key } = item;
        if (key) {
          if (acc.find(({ symbol }) => symbol === key)) {
            return acc;
          }

          const item = [...competitiveStocksInfo, mainStock].find(({ symbol }) => symbol === key);

          acc.push({
            name: `${item!.name} (${item!.symbol})`,
            symbol: key,
            color: colors[acc.length]
          });
        }

        return acc;
      }, [] as { name: string; color: string; symbol: string }[])
      .sort(function (x, y) {
        return x.symbol == mainStock.symbol ? -1 : y.symbol == mainStock.symbol ? 1 : 0;
      });

    const newSeries = getFormattedSeries(renderData.series, (symbol) => {
      const item = legends.find((item) => item.symbol === symbol);

      return {
        color: item?.color || '',
        group: item?.symbol || ''
      };
    }).filter((item: any) => {
      const seriesName = getSerieKeyFromName(item!.name);

      return !(selectedSeries && seriesName !== selectedSeries);
    });

    return {
      legends,
      newSeries
    };
  }, [renderData.series, selectedSeries]);

  const newDataLabels = useMemo(() => {
    return renderData?.dates?.map((item: any) => {
      return item;
    });
  }, [renderData?.dates]);

  const maxMinValues = useMemo(() => {
    return newSeries.reduce(
      (acc: any, item: any, index: number, arr: any) => {
        const { name } = item;
        const seriesName = getSerieKeyFromName(name);

        if (!acc[seriesName] || acc[seriesName].proceed) {
          return acc;
        }

        const allSeriesData = arr
          .filter((item: any) => getSerieKeyFromName(item.name) === seriesName)
          .reduce((acc: any, item: any) => {
            acc.push(...item.data);

            return acc;
          }, []);

        acc[seriesName] = {
          proceed: true,
          min: Math.min(...allSeriesData),
          max: Math.max(...allSeriesData)
        };

        return acc;
      },
      {
        [ChartSeriesName.price]: { min: 0, max: 0, proceed: false },
        [ChartSeriesName.chatter]: { min: 0, max: 0, proceed: false },
        [ChartSeriesName.volume]: { min: 0, max: 0, proceed: false }
      }
    );
  }, [newSeries]);

  const yAxis = useMemo(() => {
    return newSeries.reduce((acc: any, item: any) => {
      const { name } = item;
      const seriesName = getSerieKeyFromName(name);

      const exist = acc.find(({ name }: any) => {
        return getSerieKeyFromName(name) === seriesName;
      });

      const { min, max } = maxMinValues[seriesName] || { min: 0, max: 0 };

      if (seriesName === ChartSeriesName.price) {
        acc.push({
          name,
          show: !exist,
          min,
          max: max * 1.25,
          labels: {
            formatter: function (value: any) {
              return value?.toFixed(2);
            }
          }
        });
      }

      if (seriesName === ChartSeriesName.chatter) {
        acc.push({
          name,
          show: !exist,
          opposite: selectedSeries !== seriesName,
          min,
          max: max * 1.25,
          labels: {
            formatter: function (value: any) {
              return value?.toFixed(0);
            }
          }
        });
      }

      if (seriesName === ChartSeriesName.volume) {
        acc.push({
          name,
          show: selectedSeries === seriesName && !exist,
          min,
          max: max * 2,
          labels: {
            formatter: function (value: any) {
              return convertNumbersToK(value);
            }
          }
        });
      }

      return acc;
    }, []);
  }, [maxMinValues, newSeries, selectedSeries]);

  const options = useMemo(() => {
    return {
      stroke: {
        width: newSeries.map(({ name }: ILinearChartSeriesItem) => {
          if (
            getSerieKeyFromName(name) === ChartSeriesName.chatter ||
            getSerieKeyFromName(name) === ChartSeriesName.price
          ) {
            return 2;
          }

          return 0;
        }),
        curve: 'smooth',
        dashArray: newSeries.map(({ name }: ILinearChartSeriesItem) => {
          if (getSerieKeyFromName(name) === ChartSeriesName.chatter) {
            return 5;
          }

          return 0;
        })
      },
      colors,
      chart: {
        id: chartId,
        events: {
          markerClick: function (e: any) {
            const wrapper = document.getElementById('apexchartsCompetitiveChart');
            const item = document.querySelector('.apexcharts-tooltip.apexcharts-active');

            if (tooltipsCount.current >= 3) {
              return;
            }

            if (!item) {
              return;
            }

            const clone = item!.cloneNode(true) as HTMLElement;

            if (!clone || !wrapper) {
              return;
            }

            const crosshair = document.querySelector('.apexcharts-xcrosshairs.apexcharts-active');
            const apexchartsInner = document.querySelector('.apexcharts-inner');

            if (!crosshair || !apexchartsInner) {
              return;
            }

            const [transform] = apexchartsInner.getAttribute('transform')!.split(',');
            const xTranslate = parseFloat(transform.replace('translate(', ''));

            // @ts-ignore
            clone.firstElementChild.classList.add(styles.cardItem);
            setTimeout(() => {
              tooltipsCount.current += 1;
              setSelectedTooltips((prevState) => [
                ...prevState,
                {
                  id: Date.now(),
                  layout: clone.innerHTML,
                  offsetX: parseFloat(crosshair.getAttribute('x') || '0') + xTranslate
                }
              ]);
            }, 10);
          }
        },
        zoom: {
          enabled: false
        }
      },
      xaxis: {
        labels: {
          type: 'datetime',
          categories: renderData?.dates?.map((item) => item),
          formatter: function (value: any) {
            return getXaxisLabels(value, filtersRangeType, selectedTimeZone);
          },
          rotate: 0
        },
        tooltip: {
          formatter: function (value: any, data: any) {
            return getXaxisTooltipLabel(value, data, selectedTimeZone);
          }
        },
        tickAmount: 7
      },
      yaxis: yAxis,
      legend: {
        show: false
      },
      tooltip: {
        custom: function ({ seriesIndex, dataPointIndex, w }: any) {
          return renderCompetitiveTooltip({ seriesIndex, dataPointIndex, w }, selectedSeries, styles, selectedTimeZone);
        }
      }
    };
  }, [filtersRangeType, newSeries, renderData?.dates, selectedSeries, selectedTimeZone]);

  const focusChart = (name: string) => {
    return () => {
      setSelectedLegendStock(selectedLegendStock === name ? '' : name);
    };
  };

  const onSeriesFilterClick = (value: string) => {
    return () => {
      setSelectedSeries(value);
    };
  };

  const onRemoveTooltip = (id: number) => {
    return () => {
      tooltipsCount.current -= 1;
      setSelectedTooltips((prevState) => prevState.filter((item) => item.id !== id));
    };
  };

  useEffect(() => {
    const chart = ApexCharts.getChartByID(chartId);
    chart?.updateOptions({
      ...options,
      fill: {
        opacity: newSeries.map(({ name }: ILinearChartSeriesItem) => {
          const splitedName = name.split(' ');
          const stock = splitedName[splitedName.length - 1];

          if (!selectedLegendStock) {
            return 1;
          }

          if (selectedLegendStock === stock) {
            return 1;
          }

          if (selectedLegendStock) {
            return 0.2;
          }

          return 1;
        })
      }
    });
  }, [selectedLegendStock, newSeries, options]);

  const chart = useMemo(() => {
    return (
      <MixedCharts
        series={newSeries}
        labels={newDataLabels}
        /*@ts-ignore*/
        options={options}
        height={460}
      />
    );
  }, [newDataLabels, newSeries, options]);

  return (
    <SectionCard noBorder>
      <>
        <SeriesFilter filters={seriesFilters} activeFilter={selectedSeries} onClick={onSeriesFilterClick} />
        <div className={styles.headingWrapper}>
          <Heading title={t('charts.chartToMarket')} />
          <Legends legends={legends} activeLegend={selectedLegendStock} onClick={focusChart} />
        </div>
        <div className={styles.wrapper}>
          <>
            <div className={styles.chartWrapper}>{chart}</div>
            {selectedTooltips.map((item, index) => {
              const { id, offsetX } = item;
              return (
                <div key={id} className={styles.chartMarkerTooltip} style={{ left: offsetX }}>
                  <span className={styles.markerTooltipDot}>{index + 1}</span>
                </div>
              );
            })}
          </>
        </div>
        {!!selectedTooltips.length && (
          <div className={styles.tooltipCards}>
            {selectedTooltips.map((item, index) => {
              const { id, layout } = item;

              return (
                <div className={styles.tooltipCard} key={id}>
                  <div className={classNames(styles.markerTooltipDot, styles.tooltipHeaderIcon)}>{index + 1}</div>
                  <div className={styles.removeTooltip} role="button" tabIndex={-1} onClick={onRemoveTooltip(id)}>
                    &#10005;
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: layout }}></div>
                </div>
              );
            })}
          </div>
        )}
        {isLoading && <ChartSpinner />}
      </>
    </SectionCard>
  );
};

export default CompetitiveChart;
