import classNames from 'classnames';
import { NoData } from 'components';
import { IAlertsDetailsResponse } from 'modules/Alerts/types';
import { ITopPostResponse, ITopUserResponse } from 'modules/Conversation/types';
import React, { ElementType, useCallback, useEffect, useRef, useState } from 'react';
import { BaseRecord, ResponseMeta, ResponseWithPagination } from 'types';
import { fetchWithConfig } from 'utils';

import { Spinner } from '../Spinner';
import styles from './styles.module.scss';

type QueryOptions = Record<string, any>;

interface InfiniteScrollProps {
  page?: number;
  queryOptions?: QueryOptions;
  take?: number;
  updatePage: (count?: number) => void;
  ListComponent: ElementType;
  ItemComponent: ElementType;
  SkeletonComponent?: ElementType;
  requestUrl: string;
  preloaderData?: ITopPostResponse[] | ITopUserResponse[] | IAlertsDetailsResponse[];
  requestTrigger: string | boolean;
  updateCountFn?: (count: number) => void;
}

const initialDataState = {
  listData: [],
  meta: { page: 1, take: 10, hasNextPage: true, itemsCount: 0, isInitial: true }
};

export const InfiniteScroll = <TResponse extends BaseRecord = any>(props: InfiniteScrollProps) => {
  const {
    page = 1,
    take = 10,
    queryOptions = {},
    ListComponent,
    ItemComponent,
    updatePage,
    requestUrl,
    preloaderData = [],
    requestTrigger,
    updateCountFn = () => {},
    SkeletonComponent
  } = props;

  const [{ listData, meta }, setListData] = useState<{ listData: TResponse[]; meta: ResponseMeta }>(initialDataState);

  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const needUpdate = useRef<boolean>(false);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    return () => {
      updatePage(1);
    };
  }, []);

  const fetchData: () => Promise<ResponseWithPagination<TResponse> | undefined> = async () => {
    if (isLoading || !requestTrigger || error) {
      return;
    }
    setLoading(true);
    try {
      const response = await fetchWithConfig<ResponseWithPagination<TResponse>>({
        url: requestUrl,
        params: {
          ...queryOptions,
          page,
          take
        }
      });
      setListData((state) => ({
        ...state,
        listData: page === 1 ? response.list : [...state.listData, ...response.list],
        meta: response.meta
      }));
      if (response.meta?.hasNextPage) {
        updatePage();
      }
      setHasMore(response.meta.hasNextPage);
      updateCountFn(response.meta.itemsCount);
      setLoading(false);
      needUpdate.current = false;
      return response;
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    needUpdate.current = false;
    setListData(initialDataState);
    fetchData().then();
    setHasMore(true);
  }, [queryOptions, error, requestUrl, requestTrigger]);

  const loader = useRef(null);

  const handleObserver = useCallback(
    (entities: IntersectionObserverEntry[]) => {
      if (isLoading || !hasMore || !requestTrigger) {
        return;
      }

      const [target] = entities;
      if (!target.isIntersecting) {
        return;
      }
      fetchData().then(() => {});
    },
    [hasMore, isLoading, requestTrigger]
  );

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '10px',
      threshold: 0.8
    };

    const observer = new IntersectionObserver(handleObserver, options);

    if (!loader.current) {
      return;
    }

    observer.observe(loader.current);
    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, [handleObserver]);

  return (
    <>
      <ListComponent>
        {!!listData.length &&
          listData.map((item, index) => {
            return (
              <ItemComponent data={item} key={`${item.id || item._id}_${index}`} index={index} loading={isLoading} />
            );
          })}
        {!listData.length &&
          (isLoading || (!requestTrigger && meta?.isInitial)) &&
          !!preloaderData &&
          preloaderData.map((item, index) => {
            return SkeletonComponent ? (
              <SkeletonComponent key={`item_${index}`} />
            ) : ItemComponent ? (
              <ItemComponent data={item} key={`item_${index}`} loading={true} />
            ) : null;
          })}
      </ListComponent>
      {!listData.length && !error && !isLoading && <NoData />}
      {!!error && <></>}
      <div
        ref={loader}
        className={classNames(styles.loader, { [styles.hiddenLoader]: !hasMore || error || !listData.length })}
      >
        <Spinner />
      </div>
    </>
  );
};
