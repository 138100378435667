import { Typography } from '@mui/material';
import { CompanyInfo, Title, TitleType, WarningMessage } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

const OutDataPopup = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <Title nativeStyles={styles.title} type={TitleType.h1}>
        {t('pages.competitiveAnalysis.configurePopup.title')}
      </Title>
      <Title nativeStyles={styles.subTitle} type={TitleType.h3}>
        {t('pages.competitiveAnalysis.configurePopup.contactAdminTitle')}
      </Title>
      <Typography variant="body2">
        <div className={styles.warningMessage}>
          {t('pages.competitiveAnalysis.configurePopup.competitorsHintEmpty')}
          <div>
            <CompanyInfo small />
          </div>
        </div>
      </Typography>

      <div className={styles.warning}>
        <WarningMessage message={t('pages.competitiveAnalysis.configurePopup.warningMessage')} />
      </div>
    </div>
  );
};

export default OutDataPopup;
