import { createAction, createSlice } from '@reduxjs/toolkit';

import { AlertStorageTypes, IAlertsStore } from '../../types';
import { RootState } from '../index';

const initialState: IAlertsStore = {
  filteredType: 'all',
  hiddenAlertTypes: null,
  unread: 0,
  activeAlertId: '',
  isLoadingDetails: false
};

export const getActiveAlertId = (store: RootState) => store.alerts.activeAlertId;
export const getIsLoadingDetailsAlert = (store: RootState) => store.alerts.isLoadingDetails;
export const getActiveAlertType = (store: RootState) => store.alerts.filteredType;
export const getUnreadAlertCount = (store: RootState) => store.alerts.unread;
export const getHiddenTypes = (store: RootState) => store.alerts.hiddenAlertTypes;
export const setActiveAlert = createAction<string>('alertsSlice/setActiveAlertId');
export const clearActiveAlert = createAction('alertsSlice/clearActiveAlert');
export const incrementUnreadAlerts = createAction('alertsSlice/incrementUnreadAlerts');
export const clearUnreadAlerts = createAction('alertsSlice/clearUnreadAlerts');
export const setUnreadAlerts = createAction<number>('alertsSlice/setUnreadAlerts');
export const setIsLoadingDetailsAlert = createAction<boolean>('alertsSlice/setIsLoadingDetailsAlert');
export const setAlertsFilter = createAction<string | string[]>('alertsSlice/setFilterType');
export const resetAlertModule = createAction('alertsSlice/resetFilters');
export const resetAlertState = createAction('alertsSlice/resetAlertState');
export const setHiddenAlertTypes = createAction<AlertStorageTypes>('alertsSlice/setHiddenAlertTypes');

const alertsSlice = createSlice({
  name: 'alertsSlice',
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAlert, (store, { payload }) => {
        store.activeAlertId = payload;
      })
      .addCase(clearActiveAlert, (store) => {
        store.activeAlertId = '';
      })
      .addCase(incrementUnreadAlerts, (store) => {
        if (store.unread > 0) {
          store.unread--;
        }
      })
      .addCase(setIsLoadingDetailsAlert, (store, { payload }) => {
        store.isLoadingDetails = payload;
      })
      .addCase(setUnreadAlerts, (store, { payload }) => {
        store.unread = payload;
      })
      .addCase(setAlertsFilter, (store, { payload }) => {
        store.filteredType = payload;
      })
      .addCase(resetAlertState, (store) => {
        store.activeAlertId = '';
      })
      .addCase(clearUnreadAlerts, (store) => {
        store.unread = 0;
        store.activeAlertId = '';
      })
      .addCase(resetAlertModule, () => {
        return initialState;
      })
      .addCase(setHiddenAlertTypes, (store, { payload }) => {
        store.hiddenAlertTypes = payload;
      });
  }
});

export const alertsReducers = alertsSlice.reducer;
