import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { SectionCard } from '../../../components';
import { API_ROUTES } from '../../../const';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getUserStockSymbol } from '../../../store/slices/authSlice';
import {
  getFilterWithoutBotsAndRobots,
  getFiltersDatasource,
  getFiltersDateRangeFrom,
  getFiltersDateRangeTo,
  setLoadingState
} from '../../../store/slices/filtersSlice';
import { theme } from '../../../theme';
import { fetchWithConfig, numberWithCommas } from '../../../utils';
import ChartSpinner from '../ChartSpinner';
import HalfDonutChart from '../CommonCharts/HalfDonutChart';
import { preloaderSnapshotData, renderSpanShotLegend } from '../utils';
import styles from './styles.module.scss';

const windowWidth = window.innerWidth;

const SnapshotGraphs = () => {
  const filterFrom = useAppSelector(getFiltersDateRangeFrom);
  const filterTo = useAppSelector(getFiltersDateRangeTo);
  const dataSource = useAppSelector(getFiltersDatasource);
  const stockSymbol = useAppSelector(getUserStockSymbol);
  const withoutBotsAndRobots = useAppSelector(getFilterWithoutBotsAndRobots);
  const dispatch = useAppDispatch();
  const { data, error, isLoading } = useSWR<any>(
    stockSymbol
      ? [
          `${API_ROUTES.SENTIMENT_GRAPH_SNAPSHOT}/${stockSymbol}`,
          { from: filterFrom, to: filterTo, dataSource, withBotsAndRobots: !withoutBotsAndRobots }
        ]
      : null,
    (options: [string, Record<string, any>]) => {
      return fetchWithConfig({
        url: options[0],
        params: options[1]
      });
    }
  );

  useEffect(() => {
    dispatch(setLoadingState({ key: 'dateRange', value: isLoading }));
  }, [dispatch, isLoading]);

  const { t } = useTranslation();
  const colorScheme: string[] = [
    theme.palette.success.contrastText,
    theme.palette.grey['300'],
    theme.palette.error.main
  ];

  const renderData = useMemo(() => {
    return isLoading ? preloaderSnapshotData : data;
  }, [data, isLoading]);

  const newSeries = renderData?.sentiments?.map((item: any) => item.count) || [];
  const options = {
    chart: {
      toolbar: {
        show: false
      },
      stacked: false,
      background: 'transparent'
    },
    labels: renderData?.sentiments?.map((item: any) => item.label) || [],
    stroke: {
      width: 6,
      dashArray: 2,
      colors: '#101828',
      lineCap: 'round',
      endingShape: 'rounded',
      borderRadius: 4
    },
    colors: colorScheme,
    fill: {
      opacity: 1,
      colors: colorScheme
    },
    tooltip: {
      y: {
        formatter: (value: number) => numberWithCommas(value)
      }
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
      formatter: (seriesName: any, opts: any) => {
        return renderSpanShotLegend(seriesName, opts, colorScheme);
      },
      markers: {
        width: 0,
        height: 0
      },
      offsetY: windowWidth > 575 ? -70 : -30
    },

    plotOptions: {
      pie: {
        dataLabels: {
          offset: 0,
          minAngleToShowLabel: 10
        },
        startAngle: -90,
        endAngle: 90,
        offsetY: 10,
        donut: {
          endingShape: 'rounded',
          size: '88%',
          lineCap: 'round',
          labels: {
            show: true,
            value: {
              formatter: (value: number) => numberWithCommas(value)
            },
            total: {
              show: true,
              label: t('charts.labels.summary'),
              formatter: () => numberWithCommas(renderData?.total)
            }
          }
        }
      }
    }
  };

  return (
    <SectionCard nativeClassName={styles.height}>
      <>
        <div className={styles.wrapper}>
          <HalfDonutChart
            series={newSeries}
            //@ts-ignore
            options={options}
          />
        </div>
        {isLoading && <ChartSpinner size={72} />}
      </>
    </SectionCard>
  );
};

export default SnapshotGraphs;
