import { IChip, InputChipset } from 'components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mailRule } from 'services/InputFIeldsValidator/rules';

import styles from './styles.module.scss';

interface IMailingListProps {
  list: IChip[];
  onMailsChanged: (chips: IChip[]) => void;
}

const MailingListForm: React.FC<IMailingListProps> = ({ onMailsChanged }) => {
  const { t } = useTranslation();
  const [openMailInput, setOpenMailInput] = useState(false);

  return (
    <>
      {openMailInput && (
        <div className={styles.mailingList}>
          <InputChipset onChipsChanged={onMailsChanged} validateFn={mailRule} />
        </div>
      )}
      {!openMailInput && (
        <div className={styles.addList} role="button" tabIndex={-1} onClick={() => setOpenMailInput(true)}>
          {t('pages.reports.modal.addMailList')}
        </div>
      )}
    </>
  );
};

export default MailingListForm;
