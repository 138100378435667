import useSWR from 'swr';

import { API_ROUTES } from '../const';
import { IUser } from '../types';
import { fetchWithConfig } from '../utils';

const useOptionUsers = (filters?: { filter?: string; teamId?: string }) => {
  const { data, error, isLoading } = useSWR<any>(
    [API_ROUTES.SELECT_OPTION_USERS, filters ? { ...filters } : ''],
    (options: [string, Record<string, any>]) => {
      return fetchWithConfig({
        url: options[0],
        params: options[1]
      });
    }
  );

  return { usersOptionData: data as Partial<IUser>[], usersOptionError: error, usersOptionLoading: isLoading };
};

export default useOptionUsers;
