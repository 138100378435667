import { useEffect, useState } from 'react';

import { API_ROUTES } from '../const';
import { getUserStockSymbol } from '../store/slices/authSlice';
import {
  getFilterWithoutBotsAndRobots,
  getFiltersDatasource,
  getFiltersDateRangeFrom,
  getFiltersDateRangeTo,
  getTopPostTabsState,
  getTopUsersActiveId
} from '../store/slices/filtersSlice';
import { fetchWithConfig } from '../utils';
import { useAppSelector } from './index';

const useConversationCounts = (): {
  posts: {
    isLoading: boolean;
    imitateLoading: boolean;
    data: { positive: string; negative: string; neutral: string } | null;
    error: null;
  };
} => {
  const stockSymbol = useAppSelector(getUserStockSymbol);
  const filterFrom = useAppSelector(getFiltersDateRangeFrom);
  const filterTo = useAppSelector(getFiltersDateRangeTo);
  const dataSource = useAppSelector(getFiltersDatasource);
  const withoutBotsAndRobots = useAppSelector(getFilterWithoutBotsAndRobots);
  const activeTopUserId = useAppSelector(getTopUsersActiveId);
  const { value } = useAppSelector(getTopPostTabsState);
  const [data, setData] = useState<{ positive: string; negative: string; neutral: string } | null>(null);
  const [loading, setLoading] = useState(true);
  const [imitateLoading, setImitateLoading] = useState(true);

  const filtersPostCounters = {
    from: filterFrom,
    to: filterTo,
    dataSource,
    stockSymbol,
    withBotsAndRobots: !withoutBotsAndRobots,
    ...(activeTopUserId && { userId: activeTopUserId })
  };

  useEffect(() => {
    setImitateLoading(false);
  }, [value]);

  useEffect(() => {
    if (!stockSymbol) {
      return;
    }

    setLoading(true);
    setImitateLoading(true);

    fetchWithConfig({
      url: `${API_ROUTES.TOP_POST_COUNT}/${stockSymbol}`,
      params: filtersPostCounters
    }).then((res: any) => {
      setLoading(false);
      setTimeout(() => {
        setImitateLoading(false);
      }, 50);
      return setData(res);
    });
  }, [JSON.stringify(filtersPostCounters)]);

  const result = {
    posts: {
      error: null,
      isLoading: loading,
      imitateLoading,
      data
    }
  };
  return result;
};

export default useConversationCounts;
