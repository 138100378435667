import { SwitchField } from 'components';
import { useAppDispatch } from 'hooks';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { resetInfinityScrollFilters, setWithBotsAndRobots } from 'store/slices/filtersSlice';

import styles from './styles.module.scss';

interface FormValues {
  withoutBotsAndRobots: boolean;
}

const WithoutBotsAndRobots: React.FC<{ isLoading?: boolean }> = ({ isLoading }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { register, control, watch } = useForm<FormValues>({
    defaultValues: { withoutBotsAndRobots: false }
  });

  const withoutBotsAndRobots = watch('withoutBotsAndRobots');

  useEffect(() => {
    dispatch(resetInfinityScrollFilters());
    dispatch(setWithBotsAndRobots(withoutBotsAndRobots));
  }, [dispatch, withoutBotsAndRobots]);

  return (
    <SwitchField
      disabled={isLoading}
      labelStyles={styles.label}
      control={control}
      label={t('charts.withoutBotsAndRobots')}
      name="withoutBotsAndRobots"
      register={register}
    />
  );
};

export default WithoutBotsAndRobots;
