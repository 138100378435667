import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

interface IReadMoreProps {
  children: string;
  symbols?: number;
}
export const ReadMore: React.FC<IReadMoreProps> = ({ children, symbols = 300 }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const { t } = useTranslation();
  const canReadMore = text.length > symbols;

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <>
      {isReadMore && canReadMore ? text.slice(0, symbols) + '...' : text}
      {canReadMore && (
        <>
          <br />
          <button onClick={toggleReadMore} className={styles.toggleButton}>
            {isReadMore ? t('buttons.readMore') : t('buttons.showLess')}
          </button>
        </>
      )}
    </>
  );
};
